import { useMemo, useCallback } from 'react';
import { Button } from 'theme-ui';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal';

import { themed } from './Dropdown.theme';

export const DropdownOption = themed(
  ({
    children,
    option,
    selected,
    selecting,
    setSelected,
    theme,
    toggle,
    variant,
    inputRef,
    ...props
  }) => {
    // const isSelected = JSON.stringify(selected) == JSON.stringify(option);
    const isSelected = useMemo(
      () => equal(selected, option) || option?.active === 1,
      [selected, option]
    );

    const handleOnSelect = useCallback(
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!option) return;
        setSelected(option);
        toggle();
      },
      [option]
    );

    return isSelected ? null : ( // dont show the selected as an option
      <Button
        data-comp={DropdownOption.displayName}
        role="option"
        variant="buttons.plain"
        aria-selected={false}
        aria-hidden={!selecting}
        tabIndex={selecting ? '0' : '-1'}
        onClick={handleOnSelect}
        {...props}
        sx={{
          ...theme[variant]?.option,
          ...props.sx,
        }}
      >
        {children({ selecting, option, isSelected, selected })}
      </Button>
    );
  }
);

DropdownOption.displayName = 'DropdownOption';

DropdownOption.propTypes = {
  children: PropTypes.func,
  option: PropTypes.any,
  selected: PropTypes.any,
  selecting: PropTypes.bool,
  setSelected: PropTypes.func,
  toggle: PropTypes.func,
};
