import { useEffect, useRef, useState } from 'react';
import { withInView } from '@utils';

import { QuizContext } from './QuizContext';
import { QuizQuestions } from './QuizQuestions';
import { QuizMailGate } from './QuizMailGate';
import { QuizResult } from './QuizResults';
import { QuizHome } from './QuizHome';
import { themed } from './Quiz.theme';

export const QuizSection = withInView(
  themed(({ theme, cms }) => {
    const sectionRef = useRef();
    const [quizData, setQuizData] = useState({
      quizStep: 'home',
      currentQuestion: 0,
      answers: [],
      filter: '',
      hasError: false,
      isFiltered: false,
      filteredQuestions: [],
    });

    const [loaderPercentage, setLoaderPercentage] = useState('0%');

    const filterQuestionData = {
      message1: cms?.filterSettings?.filterQuestionMessage1,
      message2: cms?.filterSettings?.filterQuestionMessage2,
      isMultipleChoice: cms?.filterSettings?.isMultipleChoice,
      optionType: cms?.filterSettings?.optionType,
      optionAlignmentMobile: cms?.filterSettings?.optionAlignmentMobile,
      options: cms?.filterSettings?.filterOptions?.map((option) => {
        return {
          ...option,
          label: option.label?.trim(),
          value: option.value?.trim(),
        };
      }),
      helpTitle: cms?.filterSettings?.filterHelpTitle,
      helpContent: cms?.filterSettings?.filterHelpContent,
      isFilterQuestion: true,
    };

    const hasFilterQuestion =
      !!filterQuestionData.message1 && filterQuestionData.options.length > 0;

    const questionSettings = (cms.questions || []).map((question) => {
      return {
        ...question,
        category: question.category?.trim(),
      };
    });

    const questionList = hasFilterQuestion
      ? [filterQuestionData, ...questionSettings]
      : questionSettings;

    const quizSettings = {
      title: cms.title,
      description: cms.description,
      homeScreen: cms.homeScreen,
      questions: questionList.map((question, index) => {
        return { ...question, index };
      }),
      mailGate: cms.mailGate,
    };

    const updateQuizData = (newData) => {
      setQuizData({
        ...quizData,
        ...newData,
      });
    };

    const getResultsProducts = () => {
      const products = [];

      if (quizData?.answers?.length === 0) {
        const filteredQuestions =
          quizData.filteredQuestions?.[0]?.options?.filter((option) => {
            return option.value === quizData.filter;
          });
        if (
          filteredQuestions &&
          filteredQuestions.length > 0 &&
          filteredQuestions[0]?.productList?.length > 0
        ) {
          if (filteredQuestions[0]?.productList?.length) {
            products.push(...filteredQuestions[0].productList);
          }
        }
      } else {
        quizData?.answers?.forEach((answer) => {
          if (!answer) {
            return;
          }
          const question = quizSettings.questions.find((q) => {
            return q.index === answer.questionIndex;
          });

          if (answer.answer.indexOf('---') > -1) {
            // Question with follow up question
            const mainOption =
              question?.options[Number.parseInt(answer.answer.split('---')[0])];
            const followUpOption =
              question?.options[Number.parseInt(answer.answer.split('---')[0])]
                .followUpOptions[
                Number.parseInt(answer.answer.split('---')[1])
              ];
            if (mainOption && mainOption?.productList?.length) {
              products.push(...mainOption.productList);
            }
            if (followUpOption && followUpOption?.productList?.length) {
              products.push(...followUpOption.productList);
            }
          } else {
            // Question without follow up question
            const option = question?.options.find((option, index) => {
              return String(index) === answer.answer;
            });

            if (option && option?.productList?.length) {
              products.push(...option.productList);
            }
          }
        });
      }

      return products;
    };

    const getQAs = () => {
      const products = [];
      const qas = [];

      const filteredQuestion = quizData.filteredQuestions?.[0]?.options?.find(
        (option) => {
          return option.value === quizData.filter;
        }
      );

      if (quizData?.answers?.length === 0) {
        if (filteredQuestion) {
          qas.push({
            question: `${quizData.filteredQuestions[0]?.message1} ${quizData.filteredQuestions[0]?.message2}`,
            answer: filteredQuestion.label,
          });
        }
      } else {
        quizData?.answers?.forEach((answer) => {
          if (!answer) {
            return;
          }
          const question = quizSettings.questions.find((question) => {
            return question.index === answer.questionIndex;
          });

          if (answer.answer.indexOf('---') > -1) {
            // Question with follow up question
            const q1 = answer.answer.split('---')[0];
            const q2 = answer.answer.split('---')[1];

            qas.push({
              question: `${question?.message1}`,
              answer: `${question.options[parseFloat(q1)]?.label}`,
            });
            if (q2) {
              qas.push({
                question: `${
                  question.options[parseFloat(q1)]?.followUpMessage1
                }`,
                answer: `${
                  question.options[parseFloat(q1)]?.followUpOptions[
                    parseFloat(q2)
                  ]?.label
                }`,
              });
            }
          } else {
            // Question without follow up question
            if (answer.questionIndex === 0) {
              if (filteredQuestion) {
                qas.push({
                  question: `${quizData.filteredQuestions[0]?.message1} ${quizData.filteredQuestions[0]?.message2}`,
                  answer: filteredQuestion.label,
                });
              }
            } else {
              qas.push({
                question: `${question?.message1}`,
                answer: `${question.options[parseFloat(answer.answer)]?.label}`,
              });
            }
          }
        });
      }

      return qas;
    };

    useEffect(() => {
      if (quizData.filter === '') {
        updateQuizData({
          isFiltered: false,
          filteredQuestions: quizSettings.questions,
        });
      } else {
        const filteredQuestions = quizSettings.questions.filter((question) => {
          return (
            question.isFilterQuestion ||
            quizData.filter.includes(question.category)
          );
        });
        updateQuizData({
          isFiltered: true,
          filteredQuestions,
        });
      }
    }, [quizData.filter]);

    useEffect(() => {
      window.scrollTo({ top: 0 });
    }, [quizData.currentQuestion, quizData.quizStep]);

    useEffect(() => {
      if (quizData.quizStep === 'steps') {
        setLoaderPercentage(
          `${
            ((parseFloat(quizData.currentQuestion) + 1) /
              quizSettings.questions.length) *
            100
          }%`
        );
      } else if (quizData.quizStep === 'mailGate') {
        setLoaderPercentage('80%');
      } else if (quizData.quizStep === 'results') {
        setLoaderPercentage('100%');
      }
    }, [quizData.currentQuestion, quizData.quizStep]);

    return (
      <QuizContext.Provider
        value={{
          theme,
          quizData,
          updateQuizData,
          quizSettings,
          getResultsProducts,
          getQAs,
        }}
      >
        <section ref={sectionRef} className="quizContainer">
          {quizData.quizStep !== 'home' && (
            <div className="quiz-steps__load-bar h-1 bg-[#f3f3f3]">
              <div
                className="quiz-steps__load-bar__progress block h-full bg-[#010101] transition-all duration-200"
                style={{
                  width: loaderPercentage,
                }}
              />
            </div>
          )}
          {quizData.quizStep === 'home' && <QuizHome />}
          {quizData.quizStep === 'steps' && <QuizQuestions />}
          {quizData.quizStep === 'mailGate' && <QuizMailGate />}
          {quizData.quizStep === 'results' && <QuizResult />}
        </section>
      </QuizContext.Provider>
    );
  }),
  { triggerOnce: true }
);

QuizSection.displayName = 'Quiz Section';

QuizSection.Schema = {
  category: 'Modules',
  label: QuizSection.displayName,
  key: 'quizSection',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      component: 'group',
      name: 'homeScreen',
      label: 'Quiz Home Screen',
      fields: [
        {
          component: 'image',
          name: 'backgroundImageDesktop',
          label: 'Image Desktop',
          description: 'Recommended image size: 2880px x 1240px',
        },
        {
          component: 'image',
          name: 'backgroundImageMobile',
          label: 'Image Mobile',
          description: 'Recommended image size: 750px x 512px',
        },
        {
          component: 'text',
          name: 'subtitle',
          label: 'Sub Title',
          defaultValue: 'We crafted an idea just for you',
        },
        {
          component: 'textarea',
          name: 'title',
          label: 'Title',
          defaultValue:
            'Take our 5-minute quiz to get perfect Brumate just for you!',
        },
        {
          component: 'textarea',
          name: 'description',
          label: 'Description',
          defaultValue: 'This is a description field, Lorem Ipsum',
        },
        {
          component: 'text',
          name: 'ctaText',
          label: 'Cta Text',
          defaultValue: 'Take the Quiz',
        },
      ],
    },
    {
      component: 'group',
      name: 'filterSettings',
      label: 'Filter Question Settings',
      fields: [
        {
          component: 'textarea',
          name: 'filterQuestionMessage1',
          label: 'Filter Question Message 1',
          description:
            'Message to be shown as the intro for the filter question',
        },
        {
          component: 'textarea',
          name: 'filterQuestionMessage2',
          label: 'Filter Question Message 2',
          description:
            'Message to be shown as the follow up for the filter question',
        },
        {
          component: 'select',
          name: 'optionType',
          label: 'Option Style',
          options: [
            { value: 'with-icon', label: 'With icon' },
            { value: 'default', label: 'Without icon' },
            { value: 'with-image', label: 'With image' },
          ],
          defaultValue: 'default',
        },
        {
          component: 'select',
          name: 'optionAlignmentMobile',
          label: 'Option Alignment - Mobile',
          options: [
            { value: 'text-left', label: 'Left' },
            { value: 'justify-center text-center', label: 'Center' },
            { value: 'justify-end text-right', label: 'Right' },
          ],
          defaultValue: 'justify-center text-center',
        },
        {
          component: 'text',
          name: 'filterHelpTitle',
          label: 'Help Modal Title',
        },
        {
          component: 'group-list',
          name: 'filterHelpContent',
          label: 'Help Modal content',
          fields: [
            {
              component: 'markdown',
              name: 'body',
              label: 'Body content',
              description: 'Required, content to be displayed in the modal',
            },
          ],
        },
        {
          component: 'group-list',
          name: 'filterOptions',
          label: 'Filter Options',
          itemProps: {
            label: '{{item.label}}',
          },
          fields: [
            {
              component: 'text',
              name: 'label',
              label: 'Option Label',
              description: 'Label the customer will see on the option button',
            },
            {
              component: 'text',
              name: 'value',
              label: 'Option Value',
              description:
                "Value used to filter the questions, this value should match exactly with the reference attribute added to the questions below, if empty this will force all the questions set below to be shown with no filters, also all other selected options will be disabled so this doesn't mix up with other filters",
            },
            {
              component: 'image',
              name: 'image',
              label: 'Option Image',
              description:
                'Optional image. Recommended image size: 196px x 125px, Recommended icon size: 20px x 20px',
            },
            {
              component: 'group-list',
              name: 'productList',
              label: 'Products',
              itemProps: {
                label: '{{item.product.data.title}}',
              },
              description:
                'If no questions available, the following products will be shown on the results page',
              fields: [
                {
                  component: 'markdown',
                  name: 'resultMessage',
                  label: 'Result Message',
                  description: 'Message to be shown on the product card',
                },
                {
                  component: 'productSearch',
                  name: 'product',
                  label: 'Product',
                },
                {
                  component: 'link',
                  name: 'seeAllLink',
                  label: 'See All Link',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      component: 'group-list',
      name: 'questions',
      label: 'Questions',
      itemProps: {
        label: '{{item.category}}',
      },
      fields: [
        {
          component: 'text',
          name: 'category',
          label: 'Category',
          description:
            'Value to be used when filtering the customer flow by the first question',
        },
        {
          component: 'textarea',
          name: 'message1',
          label: 'Message 1',
          description: 'Text to be shown before the question',
        },
        {
          component: 'textarea',
          name: 'message2',
          label: 'Message 2',
          description: 'Text to be shown before the question',
        },
        {
          component: 'select',
          name: 'optionType',
          label: 'Option Style',
          options: [
            { value: 'with-icon', label: 'With icon' },
            { value: 'default', label: 'Without icon' },
            { value: 'with-image', label: 'With image' },
          ],
          defaultValue: 'default',
        },
        {
          component: 'select',
          name: 'optionAlignmentMobile',
          label: 'Option Alignment - Mobile',
          options: [
            { value: 'text-left', label: 'Left' },
            { value: 'justify-center text-center', label: 'Center' },
            { value: 'justify-end text-right', label: 'Right' },
          ],
          defaultValue: 'justify-center text-center',
        },
        {
          component: 'group-list',
          name: 'options',
          label: 'Options',
          itemProps: {
            label: '{{item.label}}',
          },
          fields: [
            {
              component: 'text',
              name: 'label',
              label: 'Option Label',
            },
            {
              component: 'image',
              name: 'image',
              label: 'Option Image',
              description:
                'Optional image. Recommended image size: 196px x 125px, Recommended icon size: 20px x 20px',
            },
            {
              component: 'group-list',
              name: 'productList',
              label: 'Products',
              itemProps: {
                label: '{{item.product.data.title}}',
              },
              fields: [
                {
                  component: 'markdown',
                  name: 'resultMessage',
                  label: 'Result Message',
                  description: 'Message to be shown on the product card',
                },
                {
                  component: 'productSearch',
                  name: 'product',
                  label: 'Product',
                },
                {
                  component: 'link',
                  name: 'seeAllLink',
                  label: 'See All Link',
                },
              ],
            },
            {
              component: 'toggle',
              name: 'hasFollowUpQuestion',
              label: 'Has Follow Up Question?',
              description:
                "(This option doesn't work with multi option question) If enabled, a follow up question will be shown after the customer selects this option, if a product hs been selected for this option it will be disregarded",
            },
            {
              component: 'textarea',
              name: 'followUpMessage1',
              label: 'Message 1',
              description: 'Text to be shown before the question',
            },
            {
              component: 'textarea',
              name: 'followUpMessage2',
              label: 'Message 2',
              description: 'Text to be shown before the question',
            },
            {
              component: 'group-list',
              name: 'followUpOptions',
              label: 'Follow Up Question Options',
              itemProps: {
                label: '{{item.label}}',
              },
              fields: [
                {
                  component: 'text',
                  name: 'label',
                  label: 'Option Label',
                },
                {
                  component: 'image',
                  name: 'image',
                  label: 'Option Image',
                  description:
                    'Optional image. Recommended image size: 196px x 125px, Recommended icon size: 20px x 20px',
                },
                {
                  component: 'group-list',
                  name: 'productList',
                  label: 'Products',
                  itemProps: {
                    label: '{{item.product.data.title}}',
                  },
                  fields: [
                    {
                      component: 'markdown',
                      name: 'resultMessage',
                      label: 'Result Message',
                      description: 'Message to be shown on the product card',
                    },
                    {
                      component: 'productSearch',
                      name: 'product',
                      label: 'Product',
                    },
                    {
                      component: 'link',
                      name: 'seeAllLink',
                      label: 'See All Link',
                    },
                  ],
                },
              ],
            },
          ],
          validate: {
            required: true,
          },
        },
        {
          component: 'text',
          name: 'helpTitle',
          label: 'Help Modal Title',
        },
        {
          component: 'group-list',
          name: 'helpContent',
          label: 'Help Modal content',
          fields: [
            {
              component: 'markdown',
              name: 'body',
              label: 'Body content',
              description: 'Required, content to be displayed in the modal',
            },
          ],
        },
      ],
    },
    {
      component: 'group',
      name: 'mailGate',
      label: 'Mail Gate Settings',
      description: 'Settings to configure the mail gate',
      fields: [
        {
          component: 'toggle',
          name: 'enableMailGate',
          label: 'Enable Mail Gate',
          toggleLabels: {
            true: 'Yes',
            false: 'No',
          },
          defaultValue: true,
        },
        {
          component: 'text',
          name: 'mailGateKlaviyoId',
          label: 'Mail Gate Klaviyo Id',
          description:
            'Klaviyo list ID to subscribe the customers to (required if mail gate is enabled)',
        },
        {
          component: 'textarea',
          name: 'title',
          label: 'Title',
          defaultValue:
            'You are just one step away from discovering the right Brumate for you!',
        },
        {
          component: 'markdown',
          name: 'description',
          label: 'Description',
          defaultValue: 'This is a description field, Lorem Ipsum',
        },
        {
          component: 'text',
          name: 'mailGateSubmitText',
          label: 'Mail Gate Submit Text',
          description:
            "text for the email form submit button, if empty defaults to 'submit'",
          defaultValue: 'Show My Results',
        },
        {
          component: 'text',
          name: 'mailGateCTAText',
          label: 'Mail Gate skip CTA Text',
          description:
            "Text for the button to skip submitting the email, if empty defaults to 'no thanks, just show me my results.'",
          defaultValue: 'Show me my results without sharing my email.',
        },
        {
          component: 'group-list',
          name: 'klaviyoProperties',
          label: 'Klaviyo Properties',
          itemProps: {
            label: '{{item.propertyKey}}',
          },
          validate: {
            maxItems: 3,
          },
          description:
            'These properties will be sent to Klaviyo when the user submits their email, this property key should match the question message',
          fields: [
            {
              component: 'text',
              name: 'propertyKey',
              label: 'Property Key',
            },
          ],
        },
      ],
    },
  ],
};
