import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.header = {
      display: 'flex',
      width: '100%',
      flexDirection: ['column', null, null, 'row'],
      mb: [14, null, null, 15],
    };

    this.heading = {
      variant: 'text.h2',
      width: '100%',
      mb: [10, null, null, 0],
      flex: 1,
      px: 9,
    };

    this.groups = {
      width: ['100%', 'auto'],
      height: ['23px', 'auto'],
      position: 'relative',
      ml: [0, null, null, 10],
      mt: [0, null, null, 11],
    };

    this.list = {
      position: ['absolute', 'relative'],
      top: [0, 'auto'],
      left: ['50%', 'auto'],
      transform: ['translateX(-50%)', 'none'],
      maxWidth: '100%',
      overflowY: 'hidden',
      overflowX: 'auto',
      px: 10,
    };

    this.listItem = {
      minWidth: 'auto',
    };

    this.button = {
      minWidth: 'unset',
      fontFamily: 'body',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
      width: 'auto',
      color: 'black',
      whiteSpace: 'nowrap',
      position: 'relative',
      overflow: 'hidden',
      pb: 1,
      ':after': {
        transition: 'transform 0.2s ease-in-out',
        content: '""',
        position: 'absolute',
        top: 'calc(100% - 2px)',
        left: 0,
        width: '100%',
        height: '2px',
        bg: 'dark',
      },
      ':hover:after': {
        transform: 'translateX(0)',
      },
    };
  })()
);
