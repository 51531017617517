import { useState } from 'react';
import { Container } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';

import { themed } from './Displays.theme';
import { Tile } from '../Tile';

export const Slider = themed(({ theme, tiles, tile }) => {
  const [swiper, setSwiper] = useState();

  const noImageAvailable = tiles.every((_tile) => !_tile.image);
  const imageRatioStyles =
    tile?.imageRatio === 'square' ? theme.square : theme.portrait;

  return (
    <Container
      data-comp={Slider.displayName}
      sx={{
        ...theme.swiper,
        // Adds minimum aspect ratio before ThreeileItem js loads
        '.swiper-slide': {
          ...(!noImageAvailable ? imageRatioStyles : ''),
        },
      }}
    >
      <Swiper onSwiper={setSwiper} {...defaultParams}>
        {tiles?.slice(0, 3).map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <Tile item={item} tile={tile} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Container>
  );
});

Slider.displayName = 'Slider';

const defaultParams = {
  centeredSlides: false,
  centeredSlidesBounds: true,
  centerInsufficientSlides: true,
  spaceBetween: 16,
  slidesPerView: 1.5,
  slidesOffsetBefore: 0,
  slidesOffsetAfter: 16,
  slidesPerGroup: 1,
  autoHeight: false,
  navigation: true,
  loop: false,
  watchOverflow: true,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  breakpoints: {
    768: {
      spaceBetween: 20,
      slidesPerView: 2,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      slidesPerGroup: 1,
    },
    1024: {
      spaceBetween: 20,
      slidesPerView: 3,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      slidesPerGroup: 1,
    },
    1280: {
      spaceBetween: 20,
      slidesPerView: 4,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      slidesPerGroup: 1,
    },
  },
};
