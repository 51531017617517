import { Spinner, Flex } from 'theme-ui';

import { Link, Picture, Badge, Favorite } from '@snippets';
import { useBreakpointValue, useProductItemImages } from '@hooks';

import { themed } from './Image.theme';

export const Image = themed(({ theme, product, view = 'default', target = '_self', handleClick }) => {
  const { primary, hover } = useProductItemImages({ product });

  const disableHoverMobile = useBreakpointValue([true, false]);

  return (
    <Link
      data-comp={Image.displayName}
      href={`/products/${product?.handle}`}
      aria-label={product?.title}
      onClick={handleClick}
      target={target}
      sx={view == 'quiz' ? theme.imageQuiz : theme.image}
    >
      <Picture
        alt={product?.title}
        images={[
          {
            src: primary?.originalSrc,
            width: 600,
            ratio: 1,
          },
        ]}
        hoverImages={[
          {
            src: hover?.originalSrc,
            width: 600,
            ratio: 1,
          },
        ]}
        disableHoverMobile={disableHoverMobile}
      >
        {!primary?.originalSrc && (
          <Flex sx={theme.spinnerWrapper}>
            <Spinner sx={theme.spinner} />
          </Flex>
        )}
      </Picture>

      <Badge handle={product?.handle} product={product} />

      <Favorite selectedVariant={product?.variants?.[0]} />
    </Link>
  );
});

Image.displayName = 'Image';
