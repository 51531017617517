import { useMemo } from 'react';

export const useProductSwiperParams = (
  { isProductRecs, loopEnabled, count } = {
    isProductRecs: false,
    loopEnabled: false,
    count: true,
  }
) => {
  const productRecsParams = useMemo(
    () => ({
      centerInsufficientSlides: !loopEnabled,
      centeredSlidesBounds: !loopEnabled,
      centeredSlides: true,
      spaceBetween: 16,
      slidesPerView: 1.5,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 16,
      slidesPerGroup: 1,
      autoHeight: false,
      navigation: true,
      loop: count ? loopEnabled : false,
      watchOverflow: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      breakpoints: {
        768: {
          spaceBetween: 20,
          slidesPerView: 2,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
        },
        1024: {
          spaceBetween: 20,
          slidesPerView: 3,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
        },
        1280: {
          spaceBetween: 20,
          slidesPerView: 4,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
        },
      },
    }),
    [loopEnabled, count]
  );

  const productCarouselParams = useMemo(
    () => ({
      centerInsufficientSlides: !loopEnabled,
      centeredSlidesBounds: !loopEnabled,
      centeredSlides: true,
      spaceBetween: 16,
      slidesPerView: 1.5,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 16,
      slidesPerGroup: 1,
      autoHeight: false,
      navigation: true,
      loop: count ? loopEnabled : false,
      watchOverflow: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      breakpoints: {
        768: {
          spaceBetween: 20,
          slidesPerView: 2,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
        },
        1024: {
          spaceBetween: 20,
          slidesPerView: 3,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
        },
        1280: {
          spaceBetween: 20,
          slidesPerView: 4,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
        },
      },
    }),
    [loopEnabled, count]
  );

  return isProductRecs ? productRecsParams : productCarouselParams;
};
