import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.image = {
      mb: 6,
      bg: 'background',
      position: 'relative',
    };

    this.imageQuiz = {
      mb: 6,
      bg: 'lightGray',
      position: 'relative',
    };

    this.spinnerWrapper = {
      width: '100%',
      minHeight: '100%',
      bg: 'lightGray',
    };

    this.spinner = {
      width: '30px',
      color: 'gray',
      m: 'auto',
    };
  })()
);
