import { useEffect, useState } from 'react';
import Script from 'next/script';

export function FueledKlaviyoScript({ withKey: klaviyoPixelId }) {
  /* Specs:
   * https://developers.klaviyo.com/en/docs/javascript_api
   * https://developers.klaviyo.com/en/docs/guide_to_integrating_a_platform_without_a_pre_built_klaviyo_integration
   */
  const [count, setCount] = useState(0);
  const [klaviyoLoaded, setKlaviyoLoaded] = useState(0);

  useEffect(() => {
    const t = window.setInterval(() => {
      if (count > 10) {
        clearInterval(t);
      }
      if (typeof window.klaviyo === 'object') {
        clearInterval(t);
        setKlaviyoLoaded(true);
      } else {
        setCount(count + 1);
      }
    }, 300);
    return () => clearInterval(t);
  }, [count]);

  useEffect(() => {
    if (!klaviyoLoaded) return;
    const identify = (event) => {
      // Identify a user
      if (event.detail.user_properties?.customer_email) {
        window.klaviyo.identify({
          email: event.detail.user_properties?.customer_email,
          first_name: event.detail.user_properties?.customer_first_name,
          last_name: event.detail.user_properties?.customer_last_name,
        });
      }
    };

    const viewItemListListener = (event) => {
      identify(event);

      window.klaviyo.push([
        'track',
        'Category View',
        {
          // Custom for BruMate.
          CategoryName: event.detail.ecommerce.collection_id,
        },
      ]);
    };

    const viewItemListener = (event) => {
      identify(event);

      const [product] = event.detail.ecommerce.detail.products;

      window.klaviyo.push([
        'track',
        'Viewed Product',
        {
          // BruMate Custom Spec
          Name: product.name,
          ProductID: product.sku,
          Categories: product.category,
          Collections: product.category,
          ImageURL: product.image,
          URL: document.location.href,
          Brand: product.brand,
          Price: product.price,
          CompareAtPrice: product.price,
        },
      ]);

      window.klaviyo.push([
        'trackViewedItem',
        {
          // BruMate Custom Spec
          Title: product.name,
          ItemId: product.sku,
          Categories: product.category,
          Collections: product.category,
          ImageUrl: product.image,
          Url: document.location.href,
          Metadata: {
            Brand: product.brand,
            Price: product.price,
            CompareAtPrice: product.price,
          },
        },
      ]);
    };

    const addToCartListener = (event) => {
      identify(event);

      const [product] = event.detail.ecommerce.add.products;
      const item = {
        Name: product.name,
        ProductID: product.sku,
        ItemId: product.product_id,
        Categories: product.category,
        Collections: product.collections,
        ImageURL: product.image,
        ImageUrl: product.image,
        URL: document.location.href,
        Url: document.location.href,
        Brand: product.brand,
        Price: product.price,
        CompareAtPrice: product.price,
        'Product ID': product.product_id,
        'Product Id': product.product_id,
        product_tags: product.tags,
        product_type: product.category,
        Title: product.name,
        title: product.name,
        'Product Title': product.name,
        'Product Price': product.price,
      };

      window.klaviyo.push(['track', 'Added to Cart', item]);

      window.klaviyo.push([
        'trackViewedItem',
        {
          Title: item.Name,
          ItemId: item.ProductID,
          Categories: item.Categories,
          Collections: item.Collections,
          ImageUrl: item.ImageURL,
          Url: item.URL,
          Metadata: {
            Brand: item.Brand,
            Price: item.Price,
            CompareAtPrice: item.CompareAtPrice,
          },
        },
      ]);
    };

    const klaviyoFormsListener = (e) => {
      if (e.detail.type === 'submit') {
        window.dataLayer.push({
          event: 'dl_subscribe',
          formId: e.detail.formId,
          formTitle: e.detail.metaData.$source,
        });
      }
    };

    const postScriptPopupListener = (e) => {
      if (e.detail.type === 'formSubmit') {
        const { popupId, popupName } = e.detail;

        // Use a regular expression to find key-value pairs in the popupName
        const keyValuePairs = {};
        const regex = /\[([^\]=]+)=([^\]]+)\]/g;
        let match;
        while ((match = regex.exec(popupName)) !== null) {
          keyValuePairs[match[1]] = match[2];
        }

        window.klaviyo.push([
          'identify',
          {
            PostscriptPopupId: popupId,
            PostscriptPopupName: popupName,
          },
        ]);
        // Send the parsed key-value pairs to Klaviyo
        if (JSON.stringify(keyValuePairs) !== '{}') {
          const _learnq = window._learnq || [];
          _learnq.push(['identify', keyValuePairs]);
        }
      }
    };

    const viewSearchResultsListener = (event) => {
      identify(event);

      const products = event.detail.ecommerce.products.map((product) => ({
        Name: product.name,
        ProductID: product.product_id,
        Price: product.price,
        Categories: product.category,
      }));

      window?.klaviyo.push([
        'track',
        'Searched Site',
        {
          SearchTerm: event.detail.ecommerce.actionField.search_term,
          ReturnedResults: products.length,
          ReturnedProducts: products,
        },
      ]);
    };

    window.addEventListener('dl_view_item', viewItemListener);
    window.addEventListener('dl_view_item_list', viewItemListListener);
    window.addEventListener('dl_add_to_cart', addToCartListener);
    window.addEventListener('klaviyoForms', klaviyoFormsListener);
    window.addEventListener('postscriptPopup', postScriptPopupListener);
    window.addEventListener(
      'dl_view_search_results',
      viewSearchResultsListener
    );

    return () => {
      window.removeEventListener('dl_view_item', viewItemListener);
      window.removeEventListener('dl_view_item_list', viewItemListListener);
      window.removeEventListener('dl_add_to_cart', addToCartListener);
      window.removeEventListener('klaviyoForms', klaviyoFormsListener);
      window.removeEventListener('postscriptPopup', postScriptPopupListener);
      window.removeEventListener(
        'dl_view_search_results',
        viewSearchResultsListener
      );
    };
  }, [klaviyoLoaded]);

  return (
    <Script
      type="text/javascript"
      id="klaviyo-script"
      async
      src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoPixelId}`}
    />
  );
}
