/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';
import { rafTimeoutInterval } from '@backpackjs/core-nextjs/utils';
import { store, StateProvider } from '@backpackjs/storefront';
import { parse } from 'telejson';

// This is new customizer bridge
import {
  renderSections,
  useCustomizerShell,
} from '@backpackjs/core-nextjs/customizerUtils';

import { Partytown, PageErrorBoundary, PreviewModeToast } from '../_components';

import sectionComponents from '../sections';
import storefrontSettingsSchema from '../settings';
import StorefrontLayout from '../layouts/Storefront';

import '../styles/storefront.css';

rafTimeoutInterval();

function StorefrontApp({
  Component,
  pageData,
  pageProps,
  router,
  storefrontSettings,
}) {
  const setRoute = store.recoil.useSetRecoilState(store.state.route);
  const setStorefrontSettings = store.recoil.useSetRecoilState(
    store.state.storefrontSettings
  );

  const config = parse(pageProps?.config || '{}');

  // update ROUTE_VIEW state
  // this is responsible for sending all the VIEW_X events
  useEffect(() => {
    setRoute(router.asPath);
  }, [router.asPath, setRoute]);

  // update the storefront settings in recoil store
  useEffect(() => {
    setStorefrontSettings(storefrontSettings);
  }, [storefrontSettings, setStorefrontSettings]);

  return (
    <>
      <Partytown {...config?.partytown} />

      {pageProps.isPreview && pageProps.isShareableToken && (
        <PreviewModeToast />
      )}

      <StorefrontLayout
        ContentForLayout={Component}
        {...pageProps}
        config={config}
        renderSections={renderSections(
          {
            ...pageProps,
            page: pageData,
          },
          sectionComponents
        )}
      />
    </>
  );
}

const useOverlayScript = ({ isPreview, overlay }) => {
  useEffect(() => {
    if (
      isPreview &&
      (overlay?.src || overlay?.version) &&
      !window.isOverlayScriptInjected
    ) {
      const script = document.createElement('script');

      script.src =
        overlay?.src ||
        `https://d3dizc9fcooe02.cloudfront.net/overlay@${overlay?.version}/dist/overlay.js`;
      script.async = true;

      document.head.appendChild(script);
      window.isOverlayScriptInjected = true;
    }
  }, [isPreview, overlay]);
};

function App(props) {
  const { Component, pageProps, router } = props;

  const { pageData, storefrontSettings } = useCustomizerShell({
    environment: pageProps.environment,
    isPreview: pageProps.isPreview,
    isShareableToken: pageProps.isShareableToken,
    sectionComponents,
    staticProps: pageProps,
    storefrontSettingsSchema,
  });

  useOverlayScript({
    isPreview: pageProps.isPreview,
    overlay: pageProps.customizerMeta?.overlay,
  });

  return (
    <PageErrorBoundary>
      <StateProvider
        pageData={pageData}
        pageProps={pageProps}
        route={router.asPath}
        storefrontSettings={storefrontSettings}
      >
        {React.cloneElement(
          <StorefrontApp />,
          {
            Component,
            pageData,
            pageProps,
            router,
            storefrontSettings,
          },
          null
        )}
      </StateProvider>
    </PageErrorBoundary>
  );
}

export default App;
