import { useCallback, useMemo, useState, useEffect } from 'react';
import { Flex, Box } from 'theme-ui';
import { useInView } from 'react-intersection-observer';
import {
  useLocalizedVariant,
  useProductByHandle,
} from '@backpackjs/storefront';

import { AddToCart, Link } from '@snippets';
import { useDataLayerActions } from '@hooks';

import { Image } from './Image';
import { Details } from './Details';
import { themed } from './SuperCollectionProductItem.theme';

export const SuperCollectionProductItem = themed(
  ({ theme, handle, seeAllLink, description, index, view, ...props }) => {
    if (!handle) return null;

    const { sendClickProductItemEvent } = useDataLayerActions();

    const [initialProduct, setInitialProduct] = useState(null);
    const [selectedHandle, setSelectedHandle] = useState(handle);

    const { ref, inView } = useInView({
      rootMargin: '400px',
      triggerOnce: true,
    });

    const { product: fullProduct } = useProductByHandle({
      handle: selectedHandle,
      fetchOnMount: inView,
    });

    const selectedVariant = useMemo(() => {
      return fullProduct?.variants?.[0];
    }, [fullProduct?.id]);

    const { localized } = useLocalizedVariant({ variant: selectedVariant });

    const handleClick = useCallback(async () => {
      sendClickProductItemEvent({
        listIndex: index,
        localized,
        product: fullProduct,
        selectedVariant,
      });
    }, [index, localized, fullProduct?.id, selectedVariant?.id]);

    useEffect(() => {
      // Update selectedHandle if product updates in cms
      if (!handle) return;
      setSelectedHandle(handle);
    }, [handle]);

    useEffect(() => {
      // Update initial product if cms product changes
      if (handle !== initialProduct?.handle && handle === fullProduct?.handle) {
        setInitialProduct(fullProduct);
      }
    }, [handle, initialProduct?.handle, fullProduct?.handle]);

    useEffect(() => {
      // Set initial product on full product mount
      if (initialProduct || !fullProduct) return;
      setInitialProduct(fullProduct);
    }, [fullProduct]);

    return (
      <Flex
        data-comp={SuperCollectionProductItem.displayName}
        ref={ref}
        sx={{
          ...props.sx,
          ...(view === 'quiz' ? theme.quizWrapper : theme.wrapper),
        }}
      >
        <Box sx={theme.content}>
          <Image product={fullProduct} handleClick={handleClick} view={view} target={view === 'quiz' ? '_blank' : '_self'} />

          {initialProduct && (
            <Details
              product={fullProduct}
              initialProduct={initialProduct}
              setSelectedHandle={setSelectedHandle}
              selectedHandle={selectedHandle}
              seeAllLink={seeAllLink}
              description={description}
              handleClick={handleClick}
              view={view}
            />
          )}
        </Box>

        {fullProduct && (
          <Flex className='flex-col md:flex-row md:justify-start justify-center items-center gap-4' sx={theme.atc}>
            <AddToCart
              product={fullProduct}
              selectedVariant={selectedVariant}
              style={{
                ...view === 'quiz' ? { mt: 0, mr: 0 } : {},
              }}
            />
            
            {view === 'quiz' && (
              <Link
                variant="buttons.articleLink"
                href={`/products/${fullProduct?.handle}`}
                tabIndex="-1"
                aria-hidden="true"
                onClick={handleClick}
                target="_blank"
                className='md:mt-5'
              >
                Learn More
              </Link>
            )}
          </Flex>
          
        )}
      </Flex>
    );
  }
);

SuperCollectionProductItem.displayName = 'SuperCollectionProductItem';
