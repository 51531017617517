import { useMemo } from 'react';
import { FueledClientScript } from './FueledClientScript';
import { FueledGTMScript } from './FueledGTMScript';
import { FueledTikTokScript } from './FueledTikTokScript';
import { FueledNorthbeamScript } from './FueledNorthbeamScript';
import { GorgiasChatWidget } from './FueledGorgiasScript';
import { FueledUsableScript } from './FueledUsableScript';
import { FueledKlaviyoScript } from './FueledKlaviyoScript';
import { FueledPostscriptScript } from './FueledPostscriptScript';
import { FueledFondueCashbackScript } from './FueledFondueCashbackScript';
import { FueledKeynesScript } from './FueledKeynesScript';

const gtmContainerId =
  /* process.env.NEXT_PUBLIC_GTM_CONTAINER_ID || */ 'GTM-P3XTC38C';
const tiktokPixelId =
  process.env.NEXT_PUBLIC_TIKTOK_PUBLIC_API_KEY || 'C18FEQF4RPCPCMC7H62G';
const klaviyoApiKey =
  process.env.NEXT_PUBLIC_KLAVIYO_PUBLIC_API_KEY || 'QC4SUa';
const postScriptShopId = process.env.NEXT_PUBLIC_POSTSCRIPT_SHOP_ID || '398366';

export function Fueled() {
  const scripts = useMemo(
    () => [
      { withKey: gtmContainerId, Component: FueledGTMScript },
      { withKey: tiktokPixelId, Component: FueledTikTokScript },
      { withKey: klaviyoApiKey, Component: FueledKlaviyoScript },
      { withKey: postScriptShopId, Component: FueledPostscriptScript },
      { Component: FueledNorthbeamScript },
      { Component: GorgiasChatWidget },
      { Component: FueledUsableScript },
      { Component: FueledFondueCashbackScript },
      {
        withKey: {
          advertiserId: 'q39posi',
          upixelId: 'd579yyz',
        },
        Component: FueledKeynesScript,
      },
    ],
    []
  );

  return (
    <>
      <FueledClientScript />
      {scripts.map(({ withKey, Component, key }, index) => (
        <Component withKey={withKey || ''} key={key || index} />
      ))}
    </>
  );
}

export default Fueled;
