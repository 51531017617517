import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.loader = {
      position: 'relative',
      width: '18px',
      height: '18px',
      borderRadius: '100%',
      background: '#7D7D7D',
      animation: 'dot-flashing 1.5s infinite linear alternate',
      animationDelay: '.5s',
      ':before': {
        content: '""',
        position: 'absolute',
        left: '-35px',
        width: '18px',
        height: '18px',
        borderRadius: '100%',
        background: '#D9D9D9',
        animation: 'dot-flashing 1.5s infinite alternate',
        animationDelay: '0s',
        top: '0',
      },
      ':after': {
        content: '""',
        position: 'absolute',
        left: '35px',
        width: '18px',
        height: '18px',
        borderRadius: '100%',
        background: '#1C1C1C',
        animation: 'dot-flashing 1.5s infinite alternate',
        animationDelay: '1s',
        top: '0',
      },
      '@keyframes dot-flashing': {
        '0%': { background: '#D9D9D9' },
        '50%': { background: '#7D7D7D' },
        '100%': { background: '#1C1C1C' },
      },
    };

    this.optionImage = {
      height: '128px',
      'img' : {
        objectFit: 'cover',
      }
    }
  })()
);
