import { useState } from 'react';
import { Container, Box } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Pagination } from 'swiper';

import { Navigation } from './Navigation';

import { themed } from './Slider.theme';
import { Slide } from './Slide';
import { useSliderParams } from './useSliderParams';

SwiperCore.use([A11y, Pagination]);

export const Slider = themed(({ theme, customParams, reviews }) => {
  const defaultParams = useSliderParams(customParams);
  const [slider, setSlider] = useState();

  return (
    <Container data-comp={Slider.displayName} sx={theme.wrapper}>
      <Box sx={theme.swiper}>
        <Swiper
          onSwiper={setSlider}
          a11y={{ enabled: true }}
          pagination={{
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          }}
          {...defaultParams}
        >
          {reviews.map((review, index) => {
            const isValidReview =
              review?.reviewer?.displayName || review?.rating || review?.body;
            if (!isValidReview) return null;
            return (
              <SwiperSlide key={index}>
                <Slide
                  key={review.rating}
                  stars={review.rating}
                  title={review.title}
                  review={review.body}
                  name={review.reviewer.displayName}
                />
              </SwiperSlide>
            );
          })}
          {/*reviews.length > 3 && (
            <Navigation
              swiper={slider}
              loop={customParams.loop}
              alt="Review"
              sx={theme.navigation}
            />
          )*/}
        </Swiper>
      </Box>
    </Container>
  );
});

Slider.displayName = 'Slider';
