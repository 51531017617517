import { useRef } from 'react';
import { Image } from 'theme-ui';
import { Picture } from '@snippets';
import { handleize } from '@utils/common';
import { useQuizContext } from './QuizContext';

export function QuizOption({
  theme,
  index,
  optionData,
  isMultipleChoice,
  optionType,
  optionAlignmentMobile,
  isFilter,
  currentAnswers,
}) {
  const context = useQuizContext();
  const { quizData, updateQuizData } = context;
  const checkbox = useRef();
  let defaultChecked = false;

  const onOptionChange = (evt) => {
    if (evt.target.type !== 'checkbox') return;
    const allInputs = evt.target
      .closest('.quiz-steps__options-container')
      .querySelectorAll('input:checked');
    if (['', 'everything'].includes(evt.target.value.toLowerCase())) {
      if (allInputs?.length) {
        const otherInput = [...allInputs].filter(
          (input) => input !== evt.target
        );
        otherInput.forEach((input) => {
          input.checked = false;
        });
      }
    } else {
      const otherInput = [...allInputs].filter((input) =>
        ['', 'everything'].includes(input.value.toLowerCase())
      );
      if (allInputs.length) {
        otherInput.forEach((input) => {
          input.checked = false;
        });
      }
    }
  };

  const removeError = () => {
    updateQuizData({
      hasError: false,
    });
  };

  if (Array.isArray(currentAnswers?.answer)) {
    defaultChecked =
      currentAnswers?.answer?.includes(String(index)) ||
      currentAnswers?.answer?.includes(optionData.value);
  } else {
    defaultChecked = currentAnswers?.answer == index;
  }

  return (
    <div
      key={`option-${index}`}
      className={`quiz__option relative md:inline-block md:align-middle md:m-2 ${
        optionType === 'with-image' ? 'sm:w-[198px] !m-0' : ''
      }`}
    >
      <input
        ref={checkbox}
        type={isMultipleChoice ? 'checkbox' : 'radio'}
        name={`question-${quizData.currentQuestion}`}
        onChange={(evt) => {
          onOptionChange(evt);
          removeError();
        }}
        value={isFilter ? optionData.value : index}
        id={handleize(optionData.label)}
        className="peer cursor-pointer appearance-none sr-only !absolute !top-0 !left-0 !w-full !h-full !border-none !bg-transparent after:!hidden before:!hidden"
      />
      <label
        htmlFor={handleize(optionData.label)}
        className={`${optionAlignmentMobile} flex items-center md:text-center h-full cursor-pointer overflow-hidden border border-[#C6C6C7] peer-checked:border-black
          ${optionType !== 'with-icon' ? '' : 'px-4'}
          ${
            optionType === 'with-image'
              ? 'items-center justify-center !text-center flex-col rounded-xl'
              : 'gap-2 rounded-3xl'
          }
        `}
      >
        {optionType !== 'default' && optionData.image && (
          <div
            className={`quiz__option-image ${
              optionType === 'with-image'
                ? 'w-full border-b border-b-[#C6C6C7]'
                : ''
            } ${
              optionType === 'with-image' && checkbox.current?.checked
                ? 'border-b-black'
                : ''
            }`}
          >
            {optionType !== 'with-icon' ? (
              <Picture
                alt={optionData.image?.alt}
                sx={theme.optionImage}
                images={[
                  {
                    src: optionData.image?.src,
                    width: 200,
                  },
                ]}
              />
            ) : (
              <Picture
                alt={optionData.image?.alt}
                width={20}
                height={20}
                sx={{
                  width: '20px',
                  height: '20px',
                }}
                images={[
                  {
                    src: optionData.image?.src,
                    width: 768,
                    ratio: 1,
                  },
                ]}
              />
            )}
          </div>
        )}
        <span
          className={`${
            optionType === 'with-image'
              ? 'flex items-center justify-center h-full py-2 !px-[11px]'
              : 'block py-1.5'
          } ${optionType !== 'with-icon' ? 'px-4' : ''}`}
        >
          {optionData.label}
        </span>
      </label>
    </div>
  );
}
