import { useEffect, useState } from 'react';
import { Box, Flex, Paragraph } from 'theme-ui';

import { useBreakpointValue } from '@hooks';
import { Svg, Link } from '@snippets';

import { ColorOption } from './ColorOption';
import { themed } from './ColorOptions.theme';

export const ColorOptions = themed(
  ({
    theme,
    initialProduct,
    selectedHandle,
    setSelectedHandle,
    seeAllLink,
    view = 'default',
  }) => {
    const maxColorsFromBp = useBreakpointValue([4, 4, 4, 5, 5]);
    const target = view === 'quiz' ? '_blank' : '_self';
    const [maxColors, setMaxColors] = useState(4);

    const handles = initialProduct.groupOptionsMap.handle;

    useEffect(() => {
      setMaxColors(maxColorsFromBp);
    }, [maxColorsFromBp]);

    return initialProduct.isGrouped && handles?.length ? (
      <Box data-comp={ColorOptions.displayName} sx={theme.option}>
        <Flex as="ul" sx={theme.values}>
          {handles.slice(0, maxColors).map((handle, index) => {
            return (
              <ColorOption
                key={index}
                handle={handle}
                selectedHandle={selectedHandle}
                setSelectedHandle={setSelectedHandle}
              />
            );
          })}

          {handles.length > maxColors && (
            <Box as="li">
              <Link
                href={seeAllLink?.url}
                newTab={seeAllLink?.newTab}
                aria-label="See all color options."
                sx={theme.seeAll}
                target={target}
              >
                <Svg
                  src="/svgs/arrow-right.svg#arrow-right"
                  alt="Arrow pointing right"
                  viewBox="0 0 24 24"
                  sx={theme.seeAll.icon}
                />
                <Paragraph sx={theme.seeAll.text}>See All</Paragraph>
              </Link>
            </Box>
          )}
        </Flex>
      </Box>
    ) : null;
  }
);

ColorOptions.displayName = 'ColorOptions';
