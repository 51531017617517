import { useEffect, useState, useRef } from 'react';
import { Heading, Flex, Paragraph, Button } from 'theme-ui';

import { Markdown, Svg } from '@snippets';
import { useBodyScrollLock } from '@hooks';
import { useQuizContext } from './QuizContext';
import { QuizOption } from './QuizOption';

export function QuizQuestions() {
  const context = useQuizContext();
  const { lockScroll, unlockScroll } = useBodyScrollLock();
  const { quizData, quizSettings, updateQuizData, theme } = context;
  const [currentAnswers, setCurrentAnswers] = useState([]);
  const [helpTextOpen, setHelpTextOpen] = useState(false);
  const stickyFooter = useRef();
  const quizStepsContainer = useRef();

  const isFollowUpQuestion = String(quizData.currentQuestion).indexOf('.') > -1;
  const parentQuestion = quizSettings.questions.find((question) => {
    return question.index == [parseInt(quizData.currentQuestion)];
  });
  const currentQuestionArrayIndex = quizData.filteredQuestions.findIndex(
    (question) => {
      return question.index === parseInt(quizData.currentQuestion);
    }
  );

  const getCurrentQuestionData = () => {
    if (!isFollowUpQuestion) {
      const currentQuestion =
        quizData.filteredQuestions.length > 0
          ? quizData.filteredQuestions?.find((question) => {
              return question.index === quizData.currentQuestion;
            })
          : quizSettings.questions.find((question) => {
              return question.index === quizData.currentQuestion;
            });

      return currentQuestion || {};
    }
    const followUpOption =
      parentQuestion.options[quizData.currentQuestion.split('.')[1]];
    return {
      isMultipleChoice: followUpOption.isMultipleChoice,
      showOptionsImages: followUpOption.showOptionsImages,
      options: followUpOption.followUpOptions,
      message1: followUpOption.followUpMessage1,
      message2: followUpOption.followUpMessage2,
      isFollowUpQuestion: true,
    };
  };

  const questionData = getCurrentQuestionData();
  const {
    isMultipleChoice,
    optionType = parentQuestion?.optionType,
    optionAlignmentMobile,
  } = questionData;

  const getListMessages = () => {
    const messages = [];
    if (questionData?.message1) {
      messages.push(questionData.message1);
    }
    if (questionData?.message2) {
      messages.push(questionData.message2);
    }

    return messages;
  };

  const navigateToNextQuestion = () => {
    const answersData = getCurrentAnswers();

    const newAnswers = [...quizData.answers];
    let hasFollowUpQuestion = false;

    if (!isMultipleChoice) {
      hasFollowUpQuestion =
        questionData?.options[answersData]?.hasFollowUpQuestion &&
        (!!questionData?.options[answersData]?.followUpMessage1 ||
          !!questionData?.options[answersData]?.followUpMessage2) &&
        questionData?.options[answersData]?.followUpOptions?.length > 0;
    }

    newAnswers[parseInt(currentQuestionArrayIndex)] = {
      questionIndex: parseInt(quizData.currentQuestion),
      answer: questionData.isFollowUpQuestion
        ? `${
            quizData.answers[parseInt(currentQuestionArrayIndex)].answer
          }---${answersData}`
        : answersData,
    };

    // Handles filter question
    if (questionData.isFilterQuestion && answersData?.length > 0) {
      const nextQuestion = quizSettings.questions.find((question) => {
        return answersData.includes(question.category);
      });

      if (nextQuestion) {
        updateQuizData({
          filter: answersData,
          currentQuestion: nextQuestion.index,
          answers: newAnswers,
          hasError: false,
        });

        return;
      }
      // If no next question but has products available, go to results / mailgate
      const filteredQuestions =
        quizData.filteredQuestions?.[0]?.options?.filter((option) => {
          return option.value === answersData;
        });

      if (quizSettings.mailGate?.enableMailGate) {
        updateQuizData({
          quizStep: 'mailGate',
          answers: [],
          filter: answersData,
          hasError: false,
        });
      } else {
        updateQuizData({
          quizStep: 'results',
          answers: [],
          filter: answersData,
          hasError: false,
        });
      }

      return;
    }

    if (!answersData || !answersData.length) {
      updateQuizData({
        hasError: true,
      });

      return;
    }

    if (
      hasFollowUpQuestion ||
      Number.parseInt(quizData.currentQuestion) !==
        quizData.filteredQuestions[quizData.filteredQuestions.length - 1].index
    ) {
      const currentQuestionArrayIndex = quizData.filteredQuestions.findIndex(
        (question) => {
          return question.index === parseInt(quizData.currentQuestion);
        }
      );
      updateQuizData({
        currentQuestion: hasFollowUpQuestion
          ? `${quizData.currentQuestion}.${answersData}`
          : quizData.filteredQuestions[currentQuestionArrayIndex + 1].index,
        answers: newAnswers,
        hasError: false,
      });
    } else if (quizSettings.mailGate?.enableMailGate) {
      updateQuizData({
        quizStep: 'mailGate',
        answers: newAnswers,
        hasError: false,
      });
    } else {
      updateQuizData({
        quizStep: 'results',
        answers: newAnswers,
        hasError: false,
      });
    }
  };

  const navigateToPreviousQuestion = () => {
    const currentQuestionArrayIndex = quizData.filteredQuestions.findIndex(
      (question) => {
        return question.index === parseInt(quizData.currentQuestion);
      }
    );

    updateQuizData({
      currentQuestion: isFollowUpQuestion
        ? parseInt(quizData.currentQuestion)
        : quizData.filteredQuestions[currentQuestionArrayIndex - 1].index,
      hasError: false,
    });
  };

  const getCurrentAnswers = () => {
    const method = isMultipleChoice ? 'querySelectorAll' : 'querySelector';
    let answers = document[method](
      '.quiz-steps__options-container input:checked'
    );
    let forceEverything = false;

    if (!answers || answers.length === 0) {
      return null;
    }

    if (questionData.isFilterQuestion && answers.value === '') {
      forceEverything = true;
    }
    answers = answers.value;

    return forceEverything ? ['everything'] : answers;
  };

  const toggleInfoModal = () => {
    setHelpTextOpen(!helpTextOpen);
    if (helpTextOpen) {
      unlockScroll(true);
    } else {
      lockScroll(true);
    }
  };

  useEffect(() => {
    const currentQuestionAnswers = quizData.answers.find(
      (answer) => answer?.questionIndex == parseInt(quizData.currentQuestion)
    );
    setCurrentAnswers(currentQuestionAnswers);
  }, [quizData.currentQuestion]);

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener('scroll', isFooterSticky);
    return () => {
      window.removeEventListener('scroll', isFooterSticky);
    };
  });

  const isFooterSticky = (e) => {
    if (quizStepsContainer.current && stickyFooter.current) {
      if (quizStepsContainer.current.getBoundingClientRect().bottom > 50) {
        // Passed the Quiz section
        stickyFooter.current.classList.remove('hidden');
      } else {
        // In the Quiz section
        stickyFooter.current.classList.add('hidden');
      }
    }
  };

  const messages = getListMessages();
  const isAnwerSelected = !!getCurrentAnswers();

  return (
    <div
      className="quiz-steps__container flex h-full w-full flex-col overflow-y-auto bg-white text-center"
      ref={quizStepsContainer}
    >
      <div
        className={`flex flex-col mx-auto w-full px-5 py-8 md:py-32 min-h-[calc(100dvh-152px)] md:min-h-[620px] ${
          optionType === 'with-image' ? 'max-w-[1130px]' : 'max-w-[900px]'
        }`}
      >
        <div className="quiz-steps__body-content">
          <div>
            {messages.length > 0 &&
              messages.map((message, index) => {
                return (
                  <Heading
                    key={`message-${quizData.questionIndex}-${index}`}
                    variant="h2"
                    className=""
                  >
                    {message}
                  </Heading>
                );
              })}
          </div>

          <Paragraph className="!mt-6">Select one option below</Paragraph>

          <fieldset
            aria-label={messages?.[0]}
            className={`quiz-steps__options-container grid pt-12 pb-16 ${
              optionType === 'with-image'
                ? 'grid-cols-2 sm:!flex flex-wrap justify-center gap-6 sm:gap-5'
                : 'md:block gap-3'
            }`}
          >
            <legend className="sr-only">Question options</legend>
            {questionData?.options?.map((option, index) => {
              return (
                <QuizOption
                  theme={theme}
                  key={option.label}
                  optionData={option}
                  isMultipleChoice={isMultipleChoice}
                  isFilter={questionData.isFilterQuestion}
                  optionType={optionType}
                  optionAlignmentMobile={optionAlignmentMobile}
                  index={index}
                  currentAnswers={currentAnswers}
                />
              );
            })}
          </fieldset>
        </div>

        <div
          className="md:!block mt-auto fixed md:static left-0 bottom-0 w-full p-5 bg-white z-[29]"
          ref={stickyFooter}
        >
          <div className="quiz-questions_footer flex items-center justify-center gap-4">
            {parseFloat(quizData.currentQuestion) > 0 && (
              <Button
                variant="buttons.secondary"
                className="mt-4 !inline-block !w-full !min-w-max md:!w-[100px]"
                onClick={() => {
                  navigateToPreviousQuestion();
                }}
              >
                Back
              </Button>
            )}

            <Button
              variant="buttons.primary"
              className={`mt-4 !inline-block !min-w-[184px] md:w-auto ${
                parseFloat(quizData.currentQuestion) > 0 ? '!w-auto' : 'w-full'
              }`}
              onClick={() => {
                navigateToNextQuestion();
              }}
              disabled={!isAnwerSelected}
            >
              Next
            </Button>
          </div>

          {questionData.helpTitle && questionData.helpContent && (
            <div className="quiz-questions__help-modal mt-4 w-full text-center">
              <Button
                variant="buttons.articleLink"
                onClick={toggleInfoModal}
                aria-label="Open Help Modal"
              >
                Need Help Deciding?
              </Button>

              {helpTextOpen && (
                <div className="quiz-help-modal fixed top-0 left-0 z-[100001] flex h-full w-full items-center justify-center text-left bg-[#00000070] p-8">
                  <div className="quiz-help-modal__body relative h-auto max-h-[80%] max-w-xl overflow-y-auto bg-white p-8">
                    <div className="absolute right-4 top-4">
                      <Button
                        className="p-2"
                        variant="buttons.plain"
                        onClick={toggleInfoModal}
                        aria-label="Close Help Modal"
                      >
                        <span className="sr-only">Close Modal</span>
                        <Svg
                          alt="Close"
                          src="/svgs/close.svg#close"
                          viewBox="0 0 48 48"
                          className="w-4 h-4"
                        />
                      </Button>
                    </div>
                    <div className="quiz-help-modal__header">
                      <Heading variant="h2">{questionData.helpTitle}</Heading>
                    </div>

                    {questionData.helpContent.length > 0 && (
                      <Markdown
                        className="!mt-6"
                        text={questionData.helpContent[0]?.body}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
