import { createContext, useContext } from 'react';

export const QuizContext = createContext({
  quizSettings: {},
  quizData: {},
  theme: {},
  updateQuizData: () => {},
  getResultsProducts: () => {},
});

export function useQuizContext() {
  return useContext(QuizContext);
}
