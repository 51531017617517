import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.swiper = {
      '.swiper-container': {
        cursor: ['grab', 'auto'],
        pl: ['16px', 'unset'],
        overflow: 'visible',
      },
      '.swiper-container, .swiper-wrapper': {
        height: 'auto !important',
        width: '100%',
      },
      '.swiper-slide': {
        opacity: 0.35,
      },
      '.swiper-slide-visible': {
        opacity: 1,
      },
      '.swiper-button-prev': {
        left: 'auto',
        right: '56px',
        top: ['-58px', null, null, '-66px'],
        color: 'black',
        width: '36px',
        height: '36px',
        '@media only screen and (max-width: 767px)': {
          display: 'none',
        },
      },
      '.swiper-button-prev:after': {
        fontSize: '30px',
      },
      '.swiper-button-next': {
        left: 'auto',
        right: 0,
        top: ['-58px', null, null, '-66px'],
        color: 'black',
        width: '36px',
        height: '36px',
        '@media only screen and (max-width: 767px)': {
          display: 'none',
        },
      },
      '.swiper-button-next:after': {
        fontSize: '30px',
      },
    };

    this.grid = {
      display: 'grid',
      gridGap: [10],
      gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
    };

    this.portrait = {
      width: '100%',
      ':before': {
        content: '""',
        pb: `${(1 / 0.75) * 100}%`,
        float: 'left',
      },
    };

    this.square = {
      width: '100%',
      ':before': {
        content: '""',
        pb: '100%',
        float: 'left',
      },
    };
  })()
);
