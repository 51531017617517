import { useCallback } from 'react';
import { Container, Box, Link, Flex } from 'theme-ui';
import { useActions, useVariables } from '@tapcart/webbridge-react';

import { withInView } from '@utils';
import { Section } from '@snippets';

import { Schema } from './ProductsSlider.schema';
import { themed } from './ProductsSlider.theme';
import { Header } from './Header';
import { Slider } from './Slider';

export const ProductsSlider = withInView(
  themed(({ theme, cms }) => {
    const { products, content, loop, cta, section } = cms;
    const { isInitialized } = useVariables();
    const { openScreen } = useActions();

    const handleCtaClick = useCallback(
      (event) => {
        if (isInitialized && cta?.url) {
          event.preventDefault();
          openScreen({
            destination: {
              type: 'web',
              url:
                cta?.url?.indexOf('http') === 0
                  ? cta.url
                  : `${process.env.SITE_URL}${cta.url}`,
            },
          });
        }
      },
      [isInitialized, cta?.url]
    );

    return (
      <Section section={section} sx={{ overflow: 'hidden' }}>
        <Container data-comp={ProductsSlider.displayName} sx={theme.wrapper}>
          <Box sx={theme.header}>
            <Header content={content} />
          </Box>

          <Box sx={theme.slider}>
            <Slider
              products={products}
              ctaStyle={content?.ctaStyle}
              loop={loop}
            />
          </Box>

          {cta?.text && (
            <Flex
              variant="flex.row.center.center"
              data-comp={`${ProductsSlider.displayName}-cta`}
              sx={theme.cta}
            >
              <Link
                variant="buttons.primary"
                href={cta?.url}
                onClick={handleCtaClick}
              >
                {cta?.text}
              </Link>
            </Flex>
          )}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

ProductsSlider.displayName = 'ProductsSlider';
ProductsSlider.Schema = Schema;
