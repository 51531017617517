import { useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Flex, Heading, Button, Spinner, Box } from 'theme-ui';
import {
  useCartRemoveItem,
  useCartDiscounts,
  useSettings,
} from '@backpackjs/storefront';

import store, { useRecoilValue } from '@store';
import { Svg, Picture, Locale, Link } from '@snippets';
import { useSidebar, useCartStatus } from '@hooks';

import { useByobLineItem } from './useByobLineItem';
import { Quantity } from './Quantity';
import { Price } from './Price';
import { themed } from './LineItem.theme';

export const LineItem = themed(
  ({
    theme,
    inSidebar,
    item,
    isLast,
    cart,
    isMaxed,
    qtyInCart,
    maxQty,
    chargeItems,
    ...props
  }) => {
    const settings = useSettings();
    const cartDiscounts = useCartDiscounts();
    const { cartRemoveItem, ...removeStatus } = useCartRemoveItem({
      autoReset: 100,
    });
    const [, { setUpdatingCartStatus }] = useCartStatus();
    const [, { closeSidebar }] = useSidebar();
    const { isByob, byobMessage } = useByobLineItem({
      item,
      updatedAt: cart?.updatedAt,
      discountCodeApplied: cartDiscounts?.length,
    });

    const gwpVariantIds = useRecoilValue(store.gwpVariantIds);
    const [isDeleting, setIsDeleting] = useState(false);

    const { handle } = item.variant.product;
    const isGift =
      gwpVariantIds?.includes(item.variant.id) || handle === 'mystery-item';
    const isSustainabilityCoverage = handle === 'sustainability-coverage';
    const { started, finished, success, errors } = removeStatus;
    const ignoredProducts = settings?.cart?.bulkOrdering?.ignoredProducts;
    const { availableForSale } = { ...item.variant };

    const isJtbCustom = (item?.attributes || []).find((attr) => {
      return attr?.key === 'recipe_id';
    });

    const isJtbCustomUpload = (item?.attributes || []).find((attr) => {
      return attr?.key === 'personalization_type' && attr?.value === 'upload';
    });
    const minQty = useMemo(() => {
      if (isJtbCustomUpload?.value === 'upload') {
        return 24;
      }
      return 0;
    }, [isJtbCustomUpload]);

    const chargeItem = chargeItems?.[isJtbCustom?.value?.replace('uc-', '')];

    const productUrl = useMemo(() => {
      if (isJtbCustom) {
        return `/products/${handle}?variant=${item.variant.id?.replace(
          'gid://shopify/ProductVariant/',
          ''
        )}&mode=edit&recipe_id=${isJtbCustom?.value?.replace('uc-', '')}`;
      }
      if (
        handle !== 'byob-charge' &&
        handle !== 'mystery-item' &&
        !isSustainabilityCoverage
      ) {
        return `/products/${handle}?variant=${item.variant.id?.replace(
          'gid://shopify/ProductVariant/',
          ''
        )}`;
      }
      return null;
    }, [handle, isJtbCustom]);

    const fullTitle = item.variant?.product?.title;
    const image = useMemo(() => {
      if (isJtbCustom) {
        return {
          src: `https://silhouette.jtbcustom.com/api/recipes/${isJtbCustom?.value?.replace(
            'uc-',
            ''
          )}/perspectives/back`,
        };
      }
      return item.variant?.image;
    }, [item.variant?.image, isJtbCustom]);

    const sizeVariant = item.variant?.selectedOptions?.find(
      (option) => option.name === 'Size'
    );
    const colorVariant = item.variant?.selectedOptions?.find(
      (option) => option.name === 'Color'
    );

    const ignoredFromMaxQty = useMemo(
      () => ignoredProducts?.some(({ product }) => product.handle === handle),
      [handle, ignoredProducts]
    );

    const title = useMemo(() => {
      const splitIndex = fullTitle?.indexOf('|') || -1;
      return {
        product:
          splitIndex >= 0 ? fullTitle?.slice(0, splitIndex).trim() : fullTitle,
        variant: splitIndex >= 0 ? fullTitle?.slice(splitIndex + 1).trim() : '',
      };
    }, [fullTitle]);

    const handleRemove = useCallback(async () => {
      setIsDeleting(true);
      await cartRemoveItem({ lineId: item.id });
      setIsDeleting(false);
    }, [item.id]);

    const discountDescription =
      item.discountAllocations?.[0]?.discountApplication?.description;

    const setGlobalCartStatusOnUpdating = useCallback(() => {
      setUpdatingCartStatus({ started, finished, success, errors });
      return () => {
        setUpdatingCartStatus({
          started: false,
          finished: false,
          success: false,
          errors: [],
        });
      };
    }, [started, finished, success]);

    const removeItemFromCartIfUnavailable = useCallback(async () => {
      if (availableForSale) return;
      setIsDeleting(true);
      await cartRemoveItem({ lineId: item.id });
      setIsDeleting(false);
    }, [availableForSale, item.id]);

    useEffect(() => {
      setGlobalCartStatusOnUpdating();
    }, [started, finished, success]);
    useEffect(() => {
      removeItemFromCartIfUnavailable();
    }, [availableForSale]);

    if (chargeItem?.id === item?.id) return null;

    return (
      <Flex
        data-comp={LineItem.displayName}
        {...props}
        sx={{
          ...theme.wrapper,
          ...props.sx,
          borderBottomStyle: isLast ? 'none' : 'solid',
        }}
      >
        {/* Image */}
        <Link
          href={productUrl}
          tabIndex="-1"
          aria-label={title.product}
          onClick={() => {
            if (!inSidebar) return;
            closeSidebar();
          }}
        >
          <Picture
            alt={title.product}
            images={[
              {
                src: image?.src,
                ratio: 1,
                width: 105,
              },
            ]}
            sx={theme.image}
          />
        </Link>

        <Flex sx={theme.details}>
          <Flex sx={theme.top}>
            <Flex sx={theme.titleWrapper}>
              {/* Product title */}
              <Link
                href={productUrl}
                onClick={() => {
                  if (!inSidebar) return;
                  closeSidebar();
                }}
              >
                <Heading as="h5" sx={theme.title}>
                  {title.product}
                </Heading>
              </Link>

              {/* Variant title */}
              {sizeVariant?.value || colorVariant?.value ? (
                <Paragraph sx={theme.variantTitle}>
                  {sizeVariant?.value || colorVariant?.value}
                </Paragraph>
              ) : (
                <Paragraph sx={theme.variantTitle}>{title.variant}</Paragraph>
              )}

              {isGift &&
                parseInt(item.estimatedCost.subtotalAmount.amount, 10) ===
                  0 && (
                  <Paragraph
                    sx={{ ...theme.variantTitle, color: isGift ? 'red' : '' }}
                  >
                    Free Gift With Purchase
                  </Paragraph>
                )}

              {isByob && !chargeItem && (
                <Paragraph sx={theme.variantTitle}>
                  With Personalization
                </Paragraph>
              )}

              {discountDescription && !byobMessage && (
                <Paragraph sx={{ ...theme.variantTitle, color: 'red' }}>
                  {discountDescription}
                </Paragraph>
              )}

              {byobMessage && (
                <Paragraph sx={{ ...theme.variantTitle, color: 'red' }}>
                  {byobMessage}
                </Paragraph>
              )}
            </Flex>

            {/* Remove button */}
            {handle !== 'byob-charge' && (
              <Button
                variant="buttons.plain"
                onClick={handleRemove}
                sx={theme.closeButton}
                aria-label="Remove item from cart"
              >
                <Svg
                  alt="Close"
                  src="/svgs/close.svg#close"
                  viewBox="0 0 48 48"
                  sx={theme.closeIcon}
                />
              </Button>
            )}
          </Flex>

          <Box sx={{ width: '100%' }}>
            <Flex sx={theme.bottom}>
              {/* Quantity select */}
              {isGift ? (
                <Box />
              ) : (
                <Quantity
                  id={item.id}
                  inSidebar={inSidebar}
                  quantity={item.quantity}
                  cartRemoveItem={cartRemoveItem}
                  setIsDeleting={setIsDeleting}
                  handle={handle}
                  isMaxed={isMaxed && !ignoredFromMaxQty}
                  maxQty={maxQty}
                  qtyInCart={qtyInCart}
                  ignoredFromMaxQty={ignoredFromMaxQty}
                  minQty={minQty}
                />
              )}

              {/* Price */}
              <Locale.CartLine line={item}>
                <Locale.Price>
                  {(props) => <Price {...props} inSidebar={inSidebar} />}
                </Locale.Price>
              </Locale.CartLine>
            </Flex>
          </Box>

          {chargeItem && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pt: '8',
              }}
            >
              <Paragraph
                sx={{
                  ...theme.variantTitle,
                  fontWeight: '500',
                  color: 'black',
                }}
              >
                + {chargeItem.variant?.product?.title}
              </Paragraph>
              <Locale.CartLine line={chargeItem}>
                <Locale.Price>
                  {(props) => <Price {...props} inSidebar={inSidebar} />}
                </Locale.Price>
              </Locale.CartLine>
            </Box>
          )}
        </Flex>

        {isDeleting && (
          <Flex sx={theme.deleting}>
            <Spinner sx={theme.deleting.spinner} />
          </Flex>
        )}
      </Flex>
    );
  }
);

LineItem.displayName = 'LineItem';
LineItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  inSidebar: PropTypes.bool,
};
