import { ProductItem } from '@snippets';
import { Container, Flex, Paragraph, Box, Spinner } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { Sort } from '../Sort';
import { Pagination } from '../Pagination';
import { useCollectionSort, useCollectionFilters } from '../hooks';
import { themed } from './CollectionGrid.theme';
import { Filter } from '../Filters/Filter';
import { CurrentFilters } from '../Filters/CurrentFilters';

export const CollectionGrid = themed(
  ({
    theme,
    products,
    merchandising,
    hasInitialProducts,
    gatedPage = null,
  }) => {
    const [{ sortOptions }] = useCollectionSort();
    const settings = useSettings();
    const sortEnabled = settings?.collection?.sortEnabled;
    const header = merchandising?.content?.header?.[0];
    const footer = merchandising?.content?.footer?.[0];
    const banner = merchandising?.content?.banner?.[0];
    const [{ totalResults }] = useCollectionFilters();

    return (
      <Container data-comp={CollectionGrid.displayName} sx={theme.wrapper}>
        {(header || banner) && (
          <Box sx={{ mb: 12, display: ['none', 'block'] }}>
            {[header, banner].map((item, index) =>
              item ? (
                <Box
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: item,
                  }}
                />
              ) : null
            )}
          </Box>
        )}

        <Box
          sx={{
            display: ['none', 'flex'],
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 8,
          }}
        >
          <CurrentFilters />
          <Box
            sx={{ display: 'flex', justifyContent: 'end', columnGap: '16px' }}
          >
            <Filter />

            {sortEnabled && sortOptions && products?.length > 0 && <Sort />}
          </Box>
        </Box>

        <Box sx={{ position: 'relative' }}>
          <Container
            sx={{
              ...theme.grid,
              gridTemplateColumns: hasInitialProducts
                ? ['repeat(2, 1fr)', 'repeat(3, 1fr)']
                : ['1fr'],
            }}
          >
            {hasInitialProducts ? (
              products?.map((product, index) => {
                if (!product) return null;
                return !product.isBanner ? (
                  <ProductItem
                    key={product.handle}
                    product={product}
                    index={index}
                  />
                ) : (
                  <Box
                    key={product.id}
                    sx={theme.banner}
                    dangerouslySetInnerHTML={{
                      __html: product.value,
                    }}
                  />
                );
              })
            ) : (
              <Flex sx={theme.noProducts}>
                <Paragraph role="status" sx={theme.noProducts.text}>
                  Sorry, there are no products in this collection.
                </Paragraph>
              </Flex>
            )}
          </Container>

          {/* Loader */}
          {hasInitialProducts && !products?.length && (
            <Flex sx={theme.loaderWrapper}>
              <Spinner sx={theme.loader} />
            </Flex>
          )}
        </Box>

        {!gatedPage && <Pagination />}

        {footer && (
          <Box
            dangerouslySetInnerHTML={{
              __html: footer,
            }}
            sx={{ mt: 12, display: ['none', 'block'] }}
          />
        )}
      </Container>
    );
  }
);

CollectionGrid.displayName = 'CollectionGrid';
