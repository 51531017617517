import { Box, Input, Label } from 'theme-ui';

import { themed } from './InputField.theme';

export const InputField = themed(
  ({ theme, label, name, type, placeholder, required, disabled, ...props }) => {
    return (
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Label className="emailLabel" sx={theme.label(disabled)} htmlFor={name}>
          {label}
        </Label>
        <Input
          required={required}
          sx={theme.input(disabled)}
          name={name}
          id={name}
          mb={3}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          {...props}
        />
      </Box>
    );
  }
);

InputField.displayName = 'InputField';
