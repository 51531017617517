import { Container, Heading, Button } from 'theme-ui';
import { Markdown } from '@snippets';
import { useQuizContext } from './QuizContext';
import { SuperCollectionProductItem } from '../SuperCollection/SuperCollectionProductItem';

export function QuizResult() {
  const context = useQuizContext();

  const { quizSettings, getResultsProducts, updateQuizData, theme } = context;

  const products = (getResultsProducts() || [])?.filter(
    (value) => Object.keys(value).length !== 0
  );

  return (
    <Container className="py-7 md:py-20 lg:py-28 px-5 max-w-[1454px] mx-auto">
      {products && products.length > 0 ? (
        <Heading variant="h2" className="text-center">
          Your Ultimate BrüMate Match Is Below
        </Heading>
      ) : (
        <Heading variant="h2" className="text-center">
          No products found.
        </Heading>
      )}

      <div
        className={`mt-8 md:mt-20 lg:mt-28 ${
          products.length > 2
            ? 'grid md:grid-cols-2 lg:grid-cols-3'
            : 'grid md:flex justify-center'
        } gap-8`}
        products={products}
      >
        {products?.map(({ product, resultMessage, seeAllLink }, index) => {
          const handle = product?.handle;
          if (!handle) return null;
          return (
            <div key={index} className="grid max-w-[450px] flex-1 w-full">
              {resultMessage && <Markdown text={resultMessage} />}
              <SuperCollectionProductItem
                handle={handle}
                view="quiz"
                seeAllLink={seeAllLink}
                index={index}
              />
            </div>
          );
        })}
      </div>

      <div className="text-center mt-16">
        <Button
          variant="buttons.secondary"
          className="!inline-block"
          onClick={() => {
            updateQuizData({
              quizStep: 'steps',
              currentQuestion: 0,
              answers: [],
              isFiltered: false,
              hasError: false,
            });
          }}
        >
          Retake The Quiz
        </Button>
      </div>
    </Container>
  );
}
