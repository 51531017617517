import { Box } from 'theme-ui';

import { Section, Markdown } from '@snippets';
import { withInView } from '@utils';
import { pxWithFullBleed } from '@utils/common';
import { useOkendo } from '@hooks';

import { Schema } from './ReviewSlider.schema';
import { themed } from './ReviewSlider.theme';

import { Slider } from './Slider';

export const ReviewSlider = withInView(
  themed(({ theme, cms, inView }) => {
    const { text, reviewSettings, sliderSettings, section } = cms;
    const { orderBy, count } = { ...reviewSettings };

    const { reviews } = useOkendo({ orderBy, count, inView });

    return (
      <Section section={section} sx={{ overflow: 'hidden' }}>
        <Box data-comp={ReviewSlider.displayName} sx={theme.wrapper}>
          {text?.heading && (
            <Markdown
              text={text?.heading}
              textSx={{
                ...theme.heading,
                textAlign: text?.textAlign,
              }}
              sx={{ ...pxWithFullBleed(section) }}
            />
          )}
          {inView && <Slider reviews={reviews} customParams={sliderSettings} />}
        </Box>
      </Section>
    );
  }),
  { triggerOnce: true }
);

ReviewSlider.displayName = 'ReviewSlider';
ReviewSlider.Schema = Schema;
