import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.label = (disabled) => ({
      variant: 'text.label.2',
      position: 'absolute',
      transformOrigin: 'top left',
      transform: 'translate(0px, 10px) ',
      width: 'auto',
      px: 10,
      color: disabled ? colors.mediumGray : colors.text,
      pointerEvents: 'none',
    });

    this.input = (disabled) => ({
      variant: 'text.lg',
      borderRadius: 50,
      borderColor: colors.mediumGray,
      width: '100%',
      px: 10,
      mt: 4,
      pb: 2,
      color: disabled ? colors.mediumGray : colors.text,
    });
  })()
);
