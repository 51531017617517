import { Heading, Flex, Paragraph, Link } from 'theme-ui';
import { Picture } from '@snippets';

import { useQuizContext } from './QuizContext';

export function QuizHome({ theme, content }) {
  const context = useQuizContext();
  const { quizSettings, updateQuizData } = context;
  const {
    backgroundImageDesktop,
    backgroundImageMobile,
    title,
    subtitle,
    description,
    ctaText,
  } = quizSettings?.homeScreen || {};

  return (
    <div className="quiz-home relative">
      {backgroundImageDesktop && (
        <Picture
          alt={backgroundImageMobile?.alt}
          className="md:!absolute inset-0 object-cover w-full h-full"
          images={[
            {
              src: backgroundImageMobile?.src,
              width: 768,
              ratio: 1,
            },
            {
              src: backgroundImageDesktop?.src,
              width: 512,
              ratio: 1,
            },
            {
              src: backgroundImageDesktop?.src,
              width: 640,
              ratio: 1,
            },
            {
              src: backgroundImageDesktop?.src,
              width: 720,
              ratio: 1,
            },
          ]}
        />
      )}

      <div className="quiz-home__inner relative md:pt-[104px] md:pb-[93px] flex items-center justify-center">
        <div className="py-10 px-6 md:py-[55px] md:px-16 bg-white">
          <div className="max-w-[410px]">
            {subtitle && (
              <Heading variant="h5" className="!mb-2">
                {subtitle}
              </Heading>
            )}
            {title && <Heading variant="h2">{title}</Heading>}
            {description && <p className="mt-4 mb-8">{description}</p>}
            <Link
              variant="buttons.primary"
              className="mt-4 !inline-block"
              onClick={() => {
                updateQuizData({
                  quizStep: 'steps',
                });
              }}
            >
              {ctaText || 'take quiz'}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
