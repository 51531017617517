import { useEffect } from 'react';
import { Box } from 'theme-ui';
import { useRouter } from '@backpackjs/storefront';

import { themed } from './Sidebar.theme';

export const SidebarShell = themed(
  ({ theme, open = false, rtl = true, inputRef, children, ...props }) => {
    const { isPreview } = useRouter();

    useEffect(() => {
      if (document.documentElement) {
        document.documentElement.classList.toggle('sidebarShell-open', open);
      }
    }, [open]);

    return (
      <Box
        as="aside"
        data-comp={SidebarShell.displayName}
        {...props}
        sx={{
          ...props.sx,
          ...theme.shell,
          ...(isPreview
            ? open
              ? theme.shell.inCustomizer.open
              : theme.shell.inCustomizer.closed
            : open
              ? theme.shell.inProduction.open
              : theme.shell.inProduction.closed),
        }}
      >
        {open ? children : null}
      </Box>
    );
  }
);

SidebarShell.displayName = 'SidebarShell';
