export default {
  label: 'Product Settings',
  name: 'product',
  component: 'group',
  fields: [
    {
      label: 'Back In Stock Modal',
      name: 'backInStock',
      component: 'group',
      fields: [
        {
          label: 'Description',
          name: 'description',
          component: 'textarea',
        },
        {
          label: 'Submit Button Text',
          name: 'modalButtonText',
          component: 'text',
        },
      ],
      defaultValue: {
        description: `Register to receive a notification when this item comes back in stock.`,
        modalButtonText: 'Notify When Available',
      },
    },
    {
      component: 'group',
      name: 'badges',
      label: 'Badges',
      fields: [
        {
          component: 'group',
          name: 'onSaleBadge',
          label: 'On Sale Badge',
          fields: [
            {
              label: 'Text',
              name: 'text',
              component: 'text',
            },
            {
              label: 'Color',
              name: 'color',
              component: 'color',
            },
            {
              label: 'Background Color',
              name: 'backgroundColor',
              component: 'color',
            },
          ],
          defaultValue: {
            text: 'Sale',
            color: '#AE2807',
            backgroundColor: 'transparent',
          },
        },
        {
          component: 'group',
          name: 'soldOutBadge',
          label: 'Sold Out Badge',
          fields: [
            {
              label: 'Text',
              name: 'text',
              component: 'text',
            },
            {
              label: 'Color',
              name: 'color',
              component: 'color',
            },
            {
              label: 'Background Color',
              name: 'backgroundColor',
              component: 'color',
            },
          ],
          defaultValue: {
            text: 'Sale',
            color: '#ffffff',
            backgroundColor: '#5d5d5d',
          },
        },
        {
          component: 'group-list',
          name: 'customBadges',
          label: 'Custom Badges',
          itemProps: {
            label: '{{item.text}}' || 'New Badge',
          },
          defaultItem: {
            text: 'New',
            color: '#000000',
            backgroundColor: 'transparent',
          },
          fields: [
            {
              label: 'Product Tag',
              name: 'tag',
              component: 'text',
              description:
                'Product tag needed to map to badge to override with settings below. Ex: badge::New',
            },
            {
              label: 'Text',
              name: 'text',
              component: 'text',
            },
            {
              label: 'Color',
              name: 'color',
              component: 'color',
            },
            {
              label: 'Background Color',
              name: 'backgroundColor',
              component: 'color',
            },
          ],
        },
      ],
      defaultValue: [
        {
          text: 'New',
          color: '#000000',
          backgroundColor: 'transparent',
        },
      ],
    },
    {
      label: 'Carousel Swatches',
      name: 'carouselSwatches',
      component: 'group',
      fields: [
        {
          label: 'Enable',
          name: 'enable',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: false,
        },
        {
          component: 'group-list',
          name: 'products',
          label: 'Products to Enable Carousel Swatches',
          description: 'Remove list to enable on all products',
          itemProps: {
            label: '{{item.product.handle}}' || 'New Product',
          },
          fields: [
            {
              component: 'productSearch',
              name: 'product',
              label: 'Product',
            },
          ],
        },
      ],
      defaultValue: {
        enable: false,
      },
    },
    {
      label: 'Icons',
      name: 'icons',
      component: 'group',
      fields: [
        {
          label: 'Icons',
          name: 'list',
          description:
            'Order of icons here will determine order of icons on product page',
          component: 'group-list',
          itemProps: {
            label: '{{item.label}}',
          },
          defaultItem: {
            tag: 'New Icon',
            label: 'New Icon',
          },
          fields: [
            {
              label: 'Tag',
              name: 'tag',
              component: 'text',
              description:
                'Tag name added to product in Shopify, e.g. "Lifetime Warranty" for "icon::warranty" in Shopfiy',
            },
            {
              label: 'Label',
              name: 'label',
              component: 'text',
            },
            {
              label: 'Icon SVG',
              name: 'icon',
              component: 'image',
            },
          ],
        },
      ],
    },
    {
      label: 'Personalize',
      name: 'personalize',
      component: 'group',
      fields: [
        {
          label: 'Personalize Button Text',
          name: 'buttonText',
          component: 'text',
        },
      ],
      defaultValue: {
        buttonText: 'Customize',
      },
    },
    {
      label: 'Specs & Fitment',
      name: 'specs',
      component: 'group',
      fields: [
        {
          label: 'Usage Guides',
          name: 'guides',
          description: 'Add product usage guide links here',
          component: 'group-list',
          itemProps: {
            label: '{{item.tag}}',
          },
          defaultItem: {
            tag: 'MultiShaker',
            link: {
              text: 'Download the Usage Guide',
              url: 'https://cdn.shopify.com/s/files/1/1114/2308/files/Digital-MultiShaker-PDF.pdf?v=1689617250',
            },
          },
          fields: [
            {
              label: 'Tag',
              name: 'tag',
              component: 'text',
              description:
                'Tag name added to product in Shopify, e.g. "MultiShaker" for "usage-guide::multishaker" in Shopfiy',
            },
            {
              label: 'Link',
              name: 'cta',
              component: 'link',
              defaultValue: {
                text: 'Download the Usage Guide',
                url: 'https://cdn.shopify.com/s/files/1/1114/2308/files/Digital-MultiShaker-PDF.pdf?v=1689617250',
              },
            },
          ],
        },
      ],
    },
    {
      label: 'Reviews',
      name: 'reviews',
      component: 'group',
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          component: 'markdown',
        },
        {
          label: 'Percentage',
          name: 'percentage',
          component: 'number',
        },
        {
          label: 'Subtext',
          name: 'subtext',
          component: 'markdown',
        },
      ],
      defaultValue: {
        heading: '## 135,000+ 5-Star Reviews',
        percentage: 100,
        subtext:
          'TRIED AND TESTED | REVIEWS FROM OUR AMAZING CREW ALL OVER THE WORLD.',
      },
    },
    {
      component: 'group',
      name: 'upsells',
      label: 'Upsells',
      fields: [
        {
          component: 'text',
          name: 'heading',
          label: 'Heading',
          defaultValue: 'Add-ons',
        },
        {
          component: 'group-list',
          name: 'products',
          label: 'Products',
          description: 'Add up to 5 products',
          itemProps: {
            label: '{{item.product.handle}}' || 'New Product',
          },
          fields: [
            {
              component: 'productSearch',
              name: 'product',
              label: 'Product',
            },
          ],
        },
      ],
    },
  ],
};
