import { useEffect } from 'react';
import { Box, Container } from 'theme-ui';

import { Overlay } from './Overlay';
import { useMenuSidebar } from './useMenuSidebar';
import { themed } from './MenuSidebar.theme';
import { MenuSidebar } from './MenuSidebar';
import { NestedSidebar } from './NestedSidebar';

export const MenuSidebarShell = themed(({ theme }) => {
  const [{ menuSidebar }] = useMenuSidebar();

  useEffect(() => {
    if (document.documentElement) {
      document.documentElement.classList.toggle(
        'sidebarShell-open',
        menuSidebar
      );
    }
  }, [menuSidebar]);

  return (
    <>
      <Overlay />

      <Box
        as="aside"
        data-comp={MenuSidebarShell.displayName}
        sx={{
          ...theme.shell,
          transform: `translateX(${menuSidebar ? '0' : '-100%'})`,
        }}
      >
        <Container
          data-comp={`${MenuSidebarShell.displayName}Scroll`}
          sx={theme.scroll}
        >
          {menuSidebar && <MenuSidebar />}
        </Container>

        {menuSidebar && <NestedSidebar />}
      </Box>
    </>
  );
});

MenuSidebarShell.displayName = 'MenuSidebarShell';
