import { useCallback } from 'react';

import store, {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from '@store';

export const useCollectionSort = () => {
  const collection = useRecoilValue(store.collection);
  const [selectedSort, setSelectedSort] = useRecoilState(store.selectedSort);
  // const setFiltersSidebar = useSetRecoilState(store.filtersSidebar);

  const sortCollection = useCallback(async ({ field, direction }) => {
    if (!field || !direction) {
      // clear sort
      setSelectedSort({ field: 'best_match', direction: 'desc' });
      return;
    }

    // Examples
    // { field: 'ss_price', direction: 'asc' },
    // { field: 'title', direction: 'desc' }
    // { field: 'ss_days_since_published', direction: 'asc' }

    setSelectedSort({ field, direction });
    // setFiltersSidebar(null);
  }, []);

  return [
    // state
    {
      selectedSort,
      sortOptions: collection?.sorting?.options?.length
        ? [
            {
              field: 'best_match',
              direction: 'desc',
              label: 'Best Match',
            },
            ...(collection?.sorting?.options || []),
          ]
        : null,
    },
    // actions
    {
      sortCollection,
    },
  ];
};
